import MuiChip from "./MuiChip";
import MuiDialogContent from "./MuiDialogContent";
import MuiFab from "./MuiFab";
import MuiFilledInput from "./MuiFilledInput";
import MuiIconButton from "./MuiIconButton";
import MuiInputBase from "./MuiInputBase";
import MuiLinearProgress from "./MuiLinearProgress";
import MuiListItemIcon from "./MuiListItemIcon";
import MuiOutlinedInput from "./MuiOutlinedInput";
import MuiSvgIcon from "./MuiSvgIcon";
import MuiTab from "./MuiTab";
import MuiTableCell from "./MuiTableCell";
import MuiTableRow from "./MuiTableRow";
import MuiTabs from "./MuiTabs";
import MuiToggleButton from "./MuiToggleButton";

const MuiOverrides = {
  // MuiButton overrides shouldn't be used, how can a button's bg colour be white? This overrides will also override all button colors which make action.color(eg, delete, update) white. 2022.02.15
  // MuiButton,
  MuiChip,
  MuiDialogContent,
  MuiFab,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiToggleButton
};

export default MuiOverrides;
