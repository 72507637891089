import React, { HTMLAttributes, Ref } from "react";
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  innerRef?: Ref<HTMLDivElement>;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%"
  }
});

export default ({ innerRef, className, ...props }: Props) => {
  const classes = useStyles();
  return (
    <div
      ref={innerRef}
      className={classNames(classes.root, className)}
      {...props}
    />
  );
};
