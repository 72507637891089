import palette from "../palette";

const MuiListItemIcon = {
  styleOverrides: {
    root: {
      color: palette.text.secondary,
      minWidth: "32px"
    }
  }
};

export default MuiListItemIcon;
