// Palette
import palette from "../palette";

const MuiFab = {
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      color: palette.text.secondary,
      "&:hover": {
        backgroundColor: palette.common.neutral
      }
    }
  }
};

export default MuiFab;
