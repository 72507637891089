import { combineReducers, createAction, AnyAction } from "@reduxjs/toolkit";
import { bimItemGroupsReducer } from "./bimItemGroupsReducer";
import { activeBimTaskIdsReducer } from "./activeBimTaskIdsReducer";
import { scheduleTaskReducer } from "./scheduleTaskReducer";
import { sectionIdReducer } from "./sectionIdReducer";

export const combinedReducer = combineReducers({
  bimItemGroups: bimItemGroupsReducer,
  activeBimTaskIds: activeBimTaskIdsReducer,
  scheduleTask: scheduleTaskReducer,
  sectionId: sectionIdReducer,
});

export const resetAppActionCreator = createAction<undefined>("RESET_APP");

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === resetAppActionCreator.type) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
