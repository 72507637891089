// This is return a API url link directly, used for image src attribute

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function timelapseImageUrl(id: string, cookie: string) {
  if (id.toUpperCase().startsWith("HTTPS:")) {
    // this id is already a s3 signed URL, can be accessed directly.
    return id;
  }
  return `${
    API_BASE_URL
  }/timelapseImage/${id}?c=${cookie}&token=${localStorage.getItem("token")}`;
}

export function webAssetUrl(id: string) {
  return `${
    API_BASE_URL
  }/asset/${id}?token=${localStorage.getItem("token")}`;
}
