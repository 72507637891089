import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { StyledProps } from "@mui/styles/styled";
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar
} from "@mui/material";

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Input as LogoutIcon
} from "@mui/icons-material";

import { useSession } from "../lib/session";
import palette from "../theme/palette";
import logo from "../assets/images/logo.webp";
import { useAuth0 } from "../components/react-auth0-spa";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${palette.border}`,
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  flex: {
    flex: 1
  },
  logo: {
    height: "1.5em"
  },
  popover: {
    width: "200px",
    textTransform: "capitalize"
  },
  toolbar: {
    minHeight: "auto",
    width: "100%",
    display: "flex"
  },
  name: {
    textTransform: "capitalize",
    marginLeft: "auto",
    textAlign: "right"
  },
  menuButton: {
    marginLeft: "-4px"
  },
  signOutButton: {},
  avatar: {
    textTransform: "uppercase",
    backgroundColor: palette.info.main,
    boxShadow: "0 0 3px 3px rgba(0, 0, 0, 0.15)"
  }
}));

interface Props extends RouteComponentProps, StyledProps {
  isSidebarOpen: boolean;
  onToggleSidebar: () => void;
}

const getInitials = (name: string | null | undefined) => {
  if (name) {
    const parts = name.split(" ");
    return parts.length > 1
      ? parts[0][0] + parts[parts.length - 1][0]
      : parts[0][0];
  } else {
    return "U";
  }
};

const Topbar = ({ className, isSidebarOpen, onToggleSidebar }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user: { name }
  } = useSession();
  const { logout } = useAuth0();
  const signOut = () => {
    localStorage.removeItem("token");
    logout({ returnTo: window.location.origin });
  };
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <AppBar position="fixed">
      <div className={classNames(classes.root, className)}>
        <Toolbar className={classes.toolbar}>
          <IconButton className={classes.menuButton} onClick={onToggleSidebar} size="large">
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <span className={classes.flex}>
            <img alt={t("logo")} className={classes.logo} src={logo} />
          </span>
          <IconButton
            className={classes.signOutButton}
            onClick={event => setAnchorEl(event.currentTarget)}
            size="large">
            <Avatar className={classes.avatar}>{getInitials(name)}</Avatar>
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <List component="nav" className={classes.popover}>
              <ListItem button onClick={signOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t("logout")} />
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default withRouter(Topbar);
