import {RoleName, useSession, ProjectUndefined} from "./session";

export default (activeProject: ProjectUndefined) => {
  const { currentUserHasRole } = useSession();
  const isViewer = currentUserHasRole(RoleName.VIEWER, activeProject);
  const isUser = currentUserHasRole(RoleName.USER, activeProject);
  const isAdmin = currentUserHasRole(RoleName.ADMIN, activeProject);
  const isSiteAdmin = currentUserHasRole(RoleName.SITEADMIN, activeProject);

  const userRoles: RoleName[] = [];
  isViewer && userRoles.push(RoleName.VIEWER);
  isUser && userRoles.push(RoleName.USER);
  isAdmin && userRoles.push(RoleName.ADMIN);
  isSiteAdmin && userRoles.push(RoleName.SITEADMIN);

  return userRoles;
};
