import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectPlanPermissionQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectPlanPermissionQuery = { __typename?: 'Query', projectPlanPermission: { __typename?: 'ProjectPlanPermission', appPlanId: string, appPlanName: string, appPermissions: Array<{ __typename?: 'ProjectPermission', id: string, name: string }> } };

export type ProjectScheduleQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectScheduleQuery = { __typename?: 'Query', projectSchedule: Array<{ __typename?: 'Schedule', id: string, name: string, description?: string | null }> };

export type ProjectCalendarsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectCalendarsQuery = { __typename?: 'Query', projectCalendars: Array<{ __typename?: 'Calendar', id: string, name: string, description: string, default?: boolean | null }> };

export type ProjectLocationQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectLocationQuery = { __typename?: 'Query', projectLocation: string };

export type ProjectActivitiesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectActivitiesQuery = { __typename?: 'Query', projectActivities: Array<{ __typename?: 'Activity', id: string, name: string, description?: string | null, type?: string | null, criticalPath: boolean, activityTrades?: Array<{ __typename?: 'ActivityTrade', trade: { __typename?: 'Trade', id: string, name: string } }> | null, activitySuppliers?: Array<{ __typename?: 'ActivitySupplier', supplier: { __typename?: 'Supplier', id: string, name: string } }> | null, activityContractors?: Array<{ __typename?: 'ActivityContractor', contractor: { __typename?: 'Contractor', id: string, name: string } }> | null }> };

export type ProjectSettingQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
  key: Types.Scalars['String'];
}>;


export type ProjectSettingQuery = { __typename?: 'Query', projectSetting?: { __typename?: 'ProjectSetting', value: string } | null };

export type ProjectCalendarDatesQueryVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type ProjectCalendarDatesQuery = { __typename?: 'Query', projectCalendarDates?: Array<{ __typename?: 'CalendarDate', id: string, calendarId: string, date: string, description: string, calendar: { __typename?: 'Calendar', name: string, description: string } }> | null };

export type CreateUserActiveProjectMutationVariables = Types.Exact<{
  projectId: Types.Scalars['String'];
}>;


export type CreateUserActiveProjectMutation = { __typename?: 'Mutation', createUserActiveProject: { __typename?: 'UserActiveProject', id: string } };


export const ProjectPlanPermissionDocument = gql`
    query ProjectPlanPermission($projectId: String!) {
  projectPlanPermission(projectId: $projectId) {
    appPlanId
    appPlanName
    appPermissions {
      id
      name
    }
  }
}
    `;

/**
 * __useProjectPlanPermissionQuery__
 *
 * To run a query within a React component, call `useProjectPlanPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPlanPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPlanPermissionQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectPlanPermissionQuery(baseOptions: Apollo.QueryHookOptions<ProjectPlanPermissionQuery, ProjectPlanPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPlanPermissionQuery, ProjectPlanPermissionQueryVariables>(ProjectPlanPermissionDocument, options);
      }
export function useProjectPlanPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPlanPermissionQuery, ProjectPlanPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPlanPermissionQuery, ProjectPlanPermissionQueryVariables>(ProjectPlanPermissionDocument, options);
        }
export type ProjectPlanPermissionQueryHookResult = ReturnType<typeof useProjectPlanPermissionQuery>;
export type ProjectPlanPermissionLazyQueryHookResult = ReturnType<typeof useProjectPlanPermissionLazyQuery>;
export type ProjectPlanPermissionQueryResult = Apollo.QueryResult<ProjectPlanPermissionQuery, ProjectPlanPermissionQueryVariables>;
export const ProjectScheduleDocument = gql`
    query ProjectSchedule($projectId: String!) {
  projectSchedule(projectId: $projectId) {
    id
    name
    description
  }
}
    `;

/**
 * __useProjectScheduleQuery__
 *
 * To run a query within a React component, call `useProjectScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectScheduleQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectScheduleQuery(baseOptions: Apollo.QueryHookOptions<ProjectScheduleQuery, ProjectScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectScheduleQuery, ProjectScheduleQueryVariables>(ProjectScheduleDocument, options);
      }
export function useProjectScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectScheduleQuery, ProjectScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectScheduleQuery, ProjectScheduleQueryVariables>(ProjectScheduleDocument, options);
        }
export type ProjectScheduleQueryHookResult = ReturnType<typeof useProjectScheduleQuery>;
export type ProjectScheduleLazyQueryHookResult = ReturnType<typeof useProjectScheduleLazyQuery>;
export type ProjectScheduleQueryResult = Apollo.QueryResult<ProjectScheduleQuery, ProjectScheduleQueryVariables>;
export const ProjectCalendarsDocument = gql`
    query ProjectCalendars($projectId: String!) {
  projectCalendars(projectId: $projectId) {
    id
    name
    description
    default
  }
}
    `;

/**
 * __useProjectCalendarsQuery__
 *
 * To run a query within a React component, call `useProjectCalendarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCalendarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCalendarsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCalendarsQuery(baseOptions: Apollo.QueryHookOptions<ProjectCalendarsQuery, ProjectCalendarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCalendarsQuery, ProjectCalendarsQueryVariables>(ProjectCalendarsDocument, options);
      }
export function useProjectCalendarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCalendarsQuery, ProjectCalendarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCalendarsQuery, ProjectCalendarsQueryVariables>(ProjectCalendarsDocument, options);
        }
export type ProjectCalendarsQueryHookResult = ReturnType<typeof useProjectCalendarsQuery>;
export type ProjectCalendarsLazyQueryHookResult = ReturnType<typeof useProjectCalendarsLazyQuery>;
export type ProjectCalendarsQueryResult = Apollo.QueryResult<ProjectCalendarsQuery, ProjectCalendarsQueryVariables>;
export const ProjectLocationDocument = gql`
    query ProjectLocation($projectId: String!) {
  projectLocation(projectId: $projectId)
}
    `;

/**
 * __useProjectLocationQuery__
 *
 * To run a query within a React component, call `useProjectLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectLocationQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectLocationQuery(baseOptions: Apollo.QueryHookOptions<ProjectLocationQuery, ProjectLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectLocationQuery, ProjectLocationQueryVariables>(ProjectLocationDocument, options);
      }
export function useProjectLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectLocationQuery, ProjectLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectLocationQuery, ProjectLocationQueryVariables>(ProjectLocationDocument, options);
        }
export type ProjectLocationQueryHookResult = ReturnType<typeof useProjectLocationQuery>;
export type ProjectLocationLazyQueryHookResult = ReturnType<typeof useProjectLocationLazyQuery>;
export type ProjectLocationQueryResult = Apollo.QueryResult<ProjectLocationQuery, ProjectLocationQueryVariables>;
export const ProjectActivitiesDocument = gql`
    query ProjectActivities($projectId: String!) {
  projectActivities(projectId: $projectId) {
    id
    name
    description
    type
    criticalPath
    activityTrades {
      trade {
        id
        name
      }
    }
    activitySuppliers {
      supplier {
        id
        name
      }
    }
    activityContractors {
      contractor {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProjectActivitiesQuery__
 *
 * To run a query within a React component, call `useProjectActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectActivitiesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectActivitiesQuery(baseOptions: Apollo.QueryHookOptions<ProjectActivitiesQuery, ProjectActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectActivitiesQuery, ProjectActivitiesQueryVariables>(ProjectActivitiesDocument, options);
      }
export function useProjectActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectActivitiesQuery, ProjectActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectActivitiesQuery, ProjectActivitiesQueryVariables>(ProjectActivitiesDocument, options);
        }
export type ProjectActivitiesQueryHookResult = ReturnType<typeof useProjectActivitiesQuery>;
export type ProjectActivitiesLazyQueryHookResult = ReturnType<typeof useProjectActivitiesLazyQuery>;
export type ProjectActivitiesQueryResult = Apollo.QueryResult<ProjectActivitiesQuery, ProjectActivitiesQueryVariables>;
export const ProjectSettingDocument = gql`
    query ProjectSetting($projectId: String!, $key: String!) {
  projectSetting(projectId: $projectId, key: $key) {
    value
  }
}
    `;

/**
 * __useProjectSettingQuery__
 *
 * To run a query within a React component, call `useProjectSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSettingQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useProjectSettingQuery(baseOptions: Apollo.QueryHookOptions<ProjectSettingQuery, ProjectSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectSettingQuery, ProjectSettingQueryVariables>(ProjectSettingDocument, options);
      }
export function useProjectSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectSettingQuery, ProjectSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectSettingQuery, ProjectSettingQueryVariables>(ProjectSettingDocument, options);
        }
export type ProjectSettingQueryHookResult = ReturnType<typeof useProjectSettingQuery>;
export type ProjectSettingLazyQueryHookResult = ReturnType<typeof useProjectSettingLazyQuery>;
export type ProjectSettingQueryResult = Apollo.QueryResult<ProjectSettingQuery, ProjectSettingQueryVariables>;
export const ProjectCalendarDatesDocument = gql`
    query ProjectCalendarDates($projectId: String!) {
  projectCalendarDates(projectId: $projectId) {
    id
    calendarId
    calendar {
      name
      description
    }
    date
    description
  }
}
    `;

/**
 * __useProjectCalendarDatesQuery__
 *
 * To run a query within a React component, call `useProjectCalendarDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCalendarDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCalendarDatesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectCalendarDatesQuery(baseOptions: Apollo.QueryHookOptions<ProjectCalendarDatesQuery, ProjectCalendarDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCalendarDatesQuery, ProjectCalendarDatesQueryVariables>(ProjectCalendarDatesDocument, options);
      }
export function useProjectCalendarDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCalendarDatesQuery, ProjectCalendarDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCalendarDatesQuery, ProjectCalendarDatesQueryVariables>(ProjectCalendarDatesDocument, options);
        }
export type ProjectCalendarDatesQueryHookResult = ReturnType<typeof useProjectCalendarDatesQuery>;
export type ProjectCalendarDatesLazyQueryHookResult = ReturnType<typeof useProjectCalendarDatesLazyQuery>;
export type ProjectCalendarDatesQueryResult = Apollo.QueryResult<ProjectCalendarDatesQuery, ProjectCalendarDatesQueryVariables>;
export const CreateUserActiveProjectDocument = gql`
    mutation CreateUserActiveProject($projectId: String!) {
  createUserActiveProject(projectId: $projectId) {
    id
  }
}
    `;
export type CreateUserActiveProjectMutationFn = Apollo.MutationFunction<CreateUserActiveProjectMutation, CreateUserActiveProjectMutationVariables>;

/**
 * __useCreateUserActiveProjectMutation__
 *
 * To run a mutation, you first call `useCreateUserActiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserActiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserActiveProjectMutation, { data, loading, error }] = useCreateUserActiveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateUserActiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserActiveProjectMutation, CreateUserActiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserActiveProjectMutation, CreateUserActiveProjectMutationVariables>(CreateUserActiveProjectDocument, options);
      }
export type CreateUserActiveProjectMutationHookResult = ReturnType<typeof useCreateUserActiveProjectMutation>;
export type CreateUserActiveProjectMutationResult = Apollo.MutationResult<CreateUserActiveProjectMutation>;
export type CreateUserActiveProjectMutationOptions = Apollo.BaseMutationOptions<CreateUserActiveProjectMutation, CreateUserActiveProjectMutationVariables>;