import palette from "../palette";

const MuiIconButton = {
  styleOverrides: {
    root: {
      padding: "10px",
      color: palette.text.secondary,
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)"
      }
    }
  }
};

export default MuiIconButton;
