// Palette
import palette from "../palette";

const MuiLinearProgress = {
  styleOverrides: {
    root: {
      borderRadius: "3px",
      overflow: "hidden"
    },
    colorPrimary: {
      backgroundColor: palette.common.neutral
    }
  }
};

export default MuiLinearProgress;
