import React from "react";
import AppContent from "./AppContent";
import { Auth0Provider } from "./components/react-auth0-spa";
import { Provider as ReduxProvider } from "react-redux";
import store from "./lib/redux";

// parse param from hash
const getHashParam = (key: string) => {
  var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
  return matches ? matches[1] : null;
};

export default () => {
  const {
    VITE_AUTH_DOMAIN: domain,
    VITE_AUTH_CLIENT_ID: clientId,
    VITE_AUTH_AUDIENCE: audience
  } = import.meta.env;

  /* PASSWORDLESS LOGIN STATE PARAMETER */
  const state = getHashParam("state");
  const decodedState = state ? decodeURIComponent(state) : null;
  /* PASSWORDLESS LOGIN STATE PARAMETER */

  const redirect_uri =
    window.location.origin +
    window.location.pathname +
    (decodedState ? decodedState : window.location.hash);

  return (
    <ReduxProvider store={store}>
      <Auth0Provider
        domain={domain || ''}
        client_id={clientId || ''}
        audience={audience || ''}
        redirect_uri={redirect_uri}
      >
        <AppContent />
      </Auth0Provider>
    </ReduxProvider>
  );
};
