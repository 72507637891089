const MuiToggleButton = {
  styleOverrides: {
    root: {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&:first-child": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px"
      },
      "&:last-child": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px"
      }
    },
    sizeSmall: {
      height: "32px"
    }
  }
};

export default MuiToggleButton;
