import { useMemo } from "react";
import { setContext } from "@apollo/client/link/context";
import { RedirectLoginOptions } from "@auth0/auth0-spa-js";
import { HttpOptions } from "@apollo/client/link/http";
import {
  ApolloClient,
  ApolloError,
  InMemoryCache,
  ServerError,
  ApolloLink,
  createHttpLink,
  from,
 } from "@apollo/client";
import { onError, ErrorHandler, ErrorResponse } from "@apollo/client/link/error";
export interface GraphQl extends HttpOptions {
  uri: HttpOptions["uri"];
  persistedQuery?: boolean;
}

const has401StatusCode = (err?: Pick<ServerError, "statusCode">) =>
  err && err.statusCode === 401;

type HasNetworkError =
  | Pick<ErrorResponse, "networkError">
  | Pick<ApolloError, "networkError">;
export const isUnAuthorizedError = ({ networkError }: HasNetworkError) =>
  has401StatusCode(networkError as ServerError);

const errorHandler = (
  loginWithRedirect: (p: RedirectLoginOptions) => void
): ErrorHandler => (error) => {
  const { networkError } = error;
  if (
    isUnAuthorizedError(error) ||
    (networkError &&
      "statusCode" in networkError &&
      has401StatusCode(networkError))
  ) {
    return loginWithRedirect({});
  }
};

interface Props {
  loginWithRedirect(p: RedirectLoginOptions): Promise<void> | void;
}

const useApolloClient = ({ loginWithRedirect }: Props) => {
  return useMemo(() => {
    const errorLink = onError(errorHandler(loginWithRedirect));
    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem("token");
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const newLink = createHttpLink({
      uri: `${import.meta.env.VITE_NEW_GRAPHQL_URL}`,
    });

    const link: ApolloLink[] = [authLink as any, errorLink, newLink];
    return new ApolloClient({
      link: from(link),
      cache: new InMemoryCache(),
    });
  }, [loginWithRedirect]);
};

export default useApolloClient;
