import { Route } from '../routes';
import { RoleName } from './session';
import { useProjectPlanPermissionQuery } from "../newGraphql/generated/Project.generated";

export enum Permission {
  PS = "Project Schedule",
  PM = "Progress Monitoring",
  G = "Gallery",
  TL = "Timelapse",
  SS = "Section Sensor",
  L = "Live",
  S = "Settings",
  SV = "Site View",
  PI = "Platform Inspection",
  LV = "Live View",
  SRP = "Short Range Program",
  LRP = "Long Range Program",
  D = "Dashboard",
  CD = "Custom Dashboard",
  WS = "Weekly Schedule",
  // TLO = "Total Labour Onsite",
  MSC = "Man Hours / Steel (Tonnes) / Concrete (M3)",
  PB = "Pour Breakdown",
  SRPAF = "Short Range Program Advanced Features",
  TV = "TV View",
  // CCD = "Cranecast Dashboard",
  CS = "Crane Schedule",
  CCS = "Cranecast Stats",
  CLR = "Crane Lift Review",
  CH = "Crane Hook",
  CPOD = "CPO Dashboard",
  CFO = "Crane Fleet Overview",
  // This main dashboard only resides under Dashboard parent menu
  MD = "Main Dashboard",
  CT = "Cranecast Timeline",
}

/**
 * @deprecated because of massive side effects
 * @param projectId
 * @constructor
 */
export const GetProjectPlanPermissions = (projectId: string) => {

  const { data  } = useProjectPlanPermissionQuery({
    variables: {
      projectId: projectId
    }
  });

  return data?.projectPlanPermission.appPermissions.map(t => t.name) ?? [];
};

/**
 * @deprecated because of massive side effects
 * @param projectId
 * @param permission
 */
export const projectPlanPermissionValidator = (
  projectId: string,
  permission: Permission
) => {
  const permissions = GetProjectPlanPermissions(projectId);
  const ifPermitted = permissions.includes(permission);
  return ifPermitted;
};

/**
 * @deprecated Please use *** from "permission.ts" instead
 *
 * TODO: create function to replace this in permission.ts
 * @param projectId
 * @param routes
 * @param userRoles
 */
export function useAllowedRoutes<T extends Route>(
  projectId: GraphQL_UUID | undefined,
  routes: T[],
  userRoles: RoleName[]
): T[] {
  // added fallback for projectId, because projectPlanPermissionValidator calls
  // another hook. -> rules of hooks want all hooks in the same order on every execution
  // means we are forced to call it even if projectId is undefined
  const allowedRoutes = routes.filter(r => !r.hidden).filter(
    (route) =>
      !route.permission ||
      projectPlanPermissionValidator(projectId ?? '12345678abcdef12345a12345678abcd', route.permission)
  );
  return allowedRoutes.filter((obj) => {
    return obj.allowedRoles.some((r) => userRoles.indexOf(r) >= 0);
  });
}
