import React, { lazy } from "react";
import { Switch, Redirect, Route } from "react-router";

import {RoleName, useSession} from "../lib/session";
import getUserRoles from "../lib/rbac";
import routes, { adminRoutes, viewerRoutes } from "./routes";
import Loader, {Spinner} from "../components/Loader";

import { useProjectPlanPermissionQuery } from "../newGraphql/generated/Project.generated";

const NotFound = lazy(() => import("../pages/NotFound"));

export default function Content(): JSX.Element {
  const { activeProject } = useSession();
  const userRoles: RoleName[] = getUserRoles(activeProject);
  const isViewer = userRoles.includes(RoleName.VIEWER);

  const { data, loading  } = useProjectPlanPermissionQuery({
    variables: {
      projectId: activeProject.id
    }
  });

  const projectPermissions: string[] = data?.projectPlanPermission.appPermissions.map(t => t.name) ?? [];

  const allowedRoutes = routes.filter( (route) => !route.permission || projectPermissions.includes(route.permission));
  const allowedNormalRoutes = allowedRoutes.filter((obj) => {
    return obj.allowedRoles.some((r) => userRoles.indexOf(r) >= 0);
  });

  const allowedRoutesPerm = adminRoutes.filter( (route) => !route.permission || projectPermissions.includes(route.permission));
  const allowedAdminRoutes = allowedRoutesPerm.filter((obj) => {
    return obj.allowedRoles.some((r) => userRoles.indexOf(r) >= 0);
  });

  // TODO: error when switching projects if we use useAllowedRoutes(activeProject.id, viewerRoutes) instead of just viewerRoutes
  const allowed = isViewer
    ? viewerRoutes
    : allowedNormalRoutes.concat(allowedAdminRoutes);
  const firstPath = allowed.length ? allowed[0].path : "";

  if(loading) return <Spinner />;
  return (
    <Loader>
      <Switch>
        {allowed.map((route) => (
          <Route exact sensitive strict key={route.path} {...route} />
        ))}
        <Redirect from="/" to={firstPath} exact strict />
        <Route component={NotFound} />
      </Switch>
    </Loader>
  );
};
