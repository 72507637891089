import palette from "../palette";

const MuiOutlinedInput = {
  styleOverrides: {
    root: {
      "&:hover:not($disabled)": {
        backgroundColor: palette.background.default
      }
    }
  }
};

export default MuiOutlinedInput;
