import React, { Suspense, PropsWithChildren, useRef } from "react";
import { SpiralSpinner, ClapSpinner } from "react-spinners-kit";
import useComponentSize from "@rehooks/component-size";
import { Box } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import Center from "./Center";
import { defaultChartHeight } from "./ApexCharts";
import palette from "../theme/palette";

export const Spinner = () => {
  const ref = useRef(null);
  const { width, height } = useComponentSize(ref);
  const size = Math.max(Math.min(width, height, 100), 50);
  return (
    <Center innerRef={ref || undefined}>
      <SpiralSpinner size={size} frontColor="#33B9C5" backColor="#4b4c56" />
    </Center>
  );
};

export const ChartProgressLoader = () => {
  return (
    <Box
      sx={{
        minHeight: defaultChartHeight,
        alignItems: "center",
        display: "flex",
      }}
    >
      <Center>
        <ClapSpinner
          size={30}
          frontColor={palette.common.fadedBlue}
          backColor={palette.common.neutral}
        />
      </Center>
    </Box>
  );
};

const Loader = (props: PropsWithChildren<{}>) => (
  <ErrorBoundary>
    <Suspense fallback={<Spinner />} {...props} />
  </ErrorBoundary>
);
export default Loader;