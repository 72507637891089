// Colors
import palette, { primary } from "../palette";

const MuiFilledInput = {
  styleOverrides: {
    root: {
      backgroundColor: palette.background.default,
      "&:hover": {
        backgroundColor: primary.light
      },
      "&$focused": {
        backgroundColor: primary.light
      }
    }
  }
};

export default MuiFilledInput;
