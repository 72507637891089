import React, { useState, useEffect, useContext } from "react";
import pickBy from "lodash/pickBy";
import createAuth0Client, {
  getIdTokenClaimsOptions,
  IdToken,
  RedirectLoginOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  Auth0ClientOptions,
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { useSetLocation } from "../lib/history";

export interface HashUrlParams {
  [property: string]: string;
}

export const getHashUrlRoute = (hashUrl: string) => {
  const matches = hashUrl.match(RegExp("#([^?]*)[\\?]?"));
  return matches ? matches[1] : null;
};

export const getHashUrlParams = (hashUrl: string) => {
  const urlSlices = hashUrl.split("?");
  const paramString = urlSlices.length === 2 ? urlSlices[1] : "";
  const searchParams = new URLSearchParams(paramString);
  const params: HashUrlParams = {};
  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};

const onRedirectCallback = (url: string | undefined) =>
  window.history.replaceState({}, document.title, url);

interface User {
  name: string;
  nickname: string;
}

interface Auth0Context {
  isAuthenticated: boolean;
  isTokenStored: boolean;
  user: User | undefined;
  loading: boolean;
  getIdTokenClaims(p?: getIdTokenClaimsOptions): Promise<IdToken> | void;
  loginWithRedirect(p: RedirectLoginOptions): Promise<void> | void;
  getTokenSilently(
    p?: GetTokenSilentlyOptions
  ): Promise<string | undefined> | void;
  getTokenWithPopup(
    p?: GetTokenWithPopupOptions
  ): Promise<string | undefined> | void;
  logout(p?: LogoutOptions): void;
}

const defaultAuth0Context = {
  isAuthenticated: false,
  isTokenStored: false,
  user: {
    name: "",
    nickname: "",
  },
  loading: false,
  loginWithPopup: () => {},
  getIdTokenClaims: () => {},
  loginWithRedirect: () => {},
  getTokenSilently: () => {},
  getTokenWithPopup: () => {},
  logout: () => {},
};

export const Auth0Context = React.createContext<Auth0Context>(
  defaultAuth0Context
);
export const useAuth0 = () => useContext(Auth0Context);

interface Auth0ProviderProps {
  children: React.ReactElement;
}

export const Auth0Provider = ({
  children,
  ...initOptions
}: Auth0ProviderProps & Auth0ClientOptions) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [isTokenStored, setIsTokenStored] = useState(false);
  const setLocation = useSetLocation();
  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      const authRedirectCondition =
        window.location.search.includes("code=") &&
        window.location.search.includes("state=");
      const nonauthRedirectCondition =
        window.location.hash.includes("access_token=") &&
        window.location.hash.includes("scope=") &&
        window.location.hash.includes("state=");

      // Auth redirect
      if (authRedirectCondition) {
        await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(initOptions.redirect_uri);
        // Passwordless without auth redirect
      } else if (nonauthRedirectCondition) {
        const hashUrl = initOptions.redirect_uri;
        const hashUrlRoute = hashUrl ? getHashUrlRoute(hashUrl) : null;
        const hashParams = hashUrl ? getHashUrlParams(hashUrl) : {};
        hashUrlRoute &&
          setLocation({
            pathname: hashUrlRoute,
            query: pickBy(hashParams),
          });
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        // @ts-ignore
        setUser(user);
        const token = await auth0FromHook.getTokenSilently();
        localStorage.setItem("token", token);
        setIsTokenStored(true);
      } else {
        await auth0FromHook.loginWithRedirect({});
      }
      setLoading(false);
    };
    if (!navigator.userAgent.includes('jsdom')) {
      initAuth0();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isTokenStored,
        user,
        loading,
        getIdTokenClaims: (...p) =>
          // @ts-ignore
        auth0Client && auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) =>
          auth0Client && auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) =>
          auth0Client && auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) =>
          auth0Client && auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client && auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
