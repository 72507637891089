import palette from "../palette";

const MuiInputBase = {
  styleOverrides: {
    root: {
      color: palette.text.secondary
    }
  }
};

export default MuiInputBase;
