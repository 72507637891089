import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

export type BimItemGroups = Array<string>;

export const updateBimItemGroupsActionCreator = createAction<BimItemGroups>(
  "UPDATE_BIM_ITEM_GROUPS"
);

export const bimItemGroupsReducer = createReducer<BimItemGroups>([], {
  [updateBimItemGroupsActionCreator.type]: (
    _,
    action: PayloadAction<BimItemGroups>
  ) => action.payload,
});
