import React, { useState } from "react";

// Externals
import PropTypes from "prop-types";

import withStyles from '@mui/styles/withStyles';

// Material components
import { Drawer } from "@mui/material";

// Component styles
import styles from "./styles";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";

export function Dashboard(props) {
    const { classes, title, children } = props;
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    return (
        <>
            <Topbar
                className={classes.topbar}
                title={title}
                isSidebarOpen={isSidebarOpen}
                onToggleSidebar={() => setSidebarOpen(prevState => !prevState)}
            />
            <Drawer
                anchor="left"
                classes={{ paper: classes.drawerPaper }}
                onClose={() => setSidebarOpen(false)}
                open={isSidebarOpen}
                variant={"temporary"}
            >
                <Sidebar className={classes.sidebar} setOpen={(o) => setSidebarOpen(o)}/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.contentWrapper}>{children}</div>
            </main>
        </>
    );
}

Dashboard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
};

export default withStyles(styles)(Dashboard);
