export default theme => ({
  topbar: {
    width: "100%",
    top: 0,
    left: 0,
    right: "auto",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerPaper: {
    width: "270px",
    height: "100%",
    backgroundColor: theme.palette.navigation.background,
  },
  sidebar: {
    width: "270px",
  },
  content: {
    marginTop: "64px",
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentWrapper: {
    maxWidth: "1600px",
    margin: "auto",
    padding: theme.spacing(1),
  },
  contentShift: {
    marginLeft: "270px"
  }
});
