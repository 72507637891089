import palette from "../../theme/palette";
import { ApexOptions } from "apexcharts";

export type ApexAxisDataObject = {
  x: any;
  y: any;
  fillColor?: string;
  strokeColor?: string;
  meta?: any;
  goals?: any;
  [key: string]: any;
}

/**
 * See apexcharts.d.ts: ApexAxisChartSeries
 * Extended [key:string]: any to reflect additional data points
 */
export type BuildAIApexAxisChartSeries = {
  name?: string
  type?: string
  color?: string
  data:
    | (number | null)[]
    | ApexAxisDataObject[]
    | [number, number | null][]
    | [number, (number | null)[]][];
}[]

export const defaultChartHeight = "350px";

/**
 * Default chart options include:
 *  - chart
 *  - legend
 *  - colors
 *  - dataLabels
 *  - grid
 *  - x axis
 *  - y axis
 * Can be overridden
 */
export const defaultChartOptions: ApexOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: "bottom",
    horizontalAlign: "left",
  },
  colors: [
    palette.common.fadedBlue,
    palette.common.green,
    palette.common.gray,
  ],
  dataLabels: {
    enabled: false,
    offsetY: -4,
    style: {
      fontSize: "10px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "normal",
      colors: [
        palette.common.fadedBlue,
        palette.common.green,
        palette.common.gray,
      ],
    },
    background: {
      enabled: false,
      dropShadow: {
        enabled: false,
      }
    },
  },
  grid: {
    show: true,
  },
  xaxis: {
    tickPlacement: "between",
    labels: { show: true },
    title: {
      text: undefined,
      offsetY: 10,
      style: {
        fontSize: "12px",
        fontWeight: 600,
      },
    },
  },
  yaxis: {
    forceNiceScale: true,
    title: {
      text: undefined,
      style: {
        fontSize: "12px",
        fontWeight: 600,
      },
    },
  },
};
