import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

export type SectionId = string | null;

export const updateSectionIdActionCreator = createAction<SectionId>(
  "UPDATE_SECTION_ID"
);

export const sectionIdReducer = createReducer<SectionId>(null, {
  [updateSectionIdActionCreator.type]: (_, action: PayloadAction<SectionId>) =>
    action.payload,
});
