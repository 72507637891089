const white = "#FFFFFF";
const black = "#0A0F0D";
const muted = "#99A9B2";

export const primary = {
  contrastText: white,
  main: "#3AC1CC",
  light: "",
  dark: "#41acb6",
};

const grey = {
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#EEEEEE",
  300: "#E0E0E0",
  400: "#BDBDBD",
  500: "#9E9E9E",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
};

const Palette = {
  grey,
  common: {
    black,
    white,
    neutral: "rgba(228,231,235,0.5)",
    main: "rgba(58,193,204,1)",
    red: "#FC5A5A",
    muted: "#C0C0C0",
    gray: "#E2E2EA",
    green: "#45CB85",
    lightgreen: "#3DD598",
    blue: "#1C77C3",
    lightblue: "#50B5FF",
    fadedBlue: "#4bc4f7",
    yellow: "rgba(255,255,0,1)",
    darkYellow: "#FFBC42",
    orange: "#FF482E",
    purple: "#88498F",
  },
  chart: {
    // Requested
    series1: "#4bc4f7",
    series2: "#45CB85",
    series3: "#FFBC42",
    series4: "#88498F",
    series5: "#FF482E",
    // Generated
    series6: "#26C6DA",
    series7: "#26A69A",
    series8: "#66BB6A",
    series9: "#9CCC65",
    series10: "#D4E157",
    series11: "#FFEE58",
    series12: "#FFCA28",
    series13: "#FF7043",
    series14: "#1C77C3",
    series16: "#3AC1CC",
    series17: "#99A9B2"
  },
  cranecastTimeline: {
    idle: "#FF5050",
    moving: "#00E396",
    rigging: "#CECECE",
    liftingMoving: "#67BDFF",
    liftingOnly: "#008FFB",
    wind: "#823EB7",
    ticker: "#22232B",
  },
  navigation: {
    active: "#242a38",
    background: "#111827",
    activeText: "#4bc4f7",
    inactiveText: "#d1d5db",
  },
  primary,
  secondary: {
    contrastText: white,
    main: "#DC004E",
    light: "#E33371",
    dark: "#9A0036",
  },
  success: {
    contrastText: white,
    main: "#45CB85",
    light: "#F1FAF5",
    dark: "#00783E",
  },
  info: {
    contrastText: white,
    main: "#1C77C3",
    light: "#F1FBFC",
    dark: "#007489",
    buttonBackground: "#D9F0FF",
    buttonForeground: "#009AFF",
  },
  warning: {
    contrastText: white,
    main: "#FFD777",
    light: "#FFE7B8",
    dark: "rgba(220,0,78,1)",
  },
  danger: {
    contrastText: white,
    main: "#FF1F1F",
    light: "#FEF6F6",
    dark: "#BF0E08",
  },
  text: {
    primary: "#373737",
    secondary: "#5D737E",
    tertiary: "#3AC1CC",
    disabled: muted,
    link: "#1C77C3",
  },
  background: {
    default: "#f8fafc",
    dark: "#31383E",
    paper: white,
  },
  caption: {
    text: "#373737",
  },
  polygon: {
    border: "rgba(255, 255, 255, 0.8)",
    fill: "#45CB85",
  },
  heatmap: {
    complete: "#27AE60",
    weatherDelay: "#EB5757",
    forecast: "rgb(133,129,129)",
    vacant: "rgb(239,238,238)",
    cycleOnTrack: "#27AE60",
    cycleVariance1: "#FFBC42",
    cycleVariance2: "#EB5757",
    currentDay: {
      primary: "#CDE5E7",
      secondary: "#DAF3F5",
      border: "#0F97D8",
    },
  },
  border: "#D5DFE5",
  divider: "#D5DFE5",
  errorColor: "#FF5050",
  orderStatus: {
    default: "#E2E2EA",
    orderPlaced: "#febb42",
    supplierConfirmed: "#44cb84",
    rescheduling: "#EB5757",
    delivered: "#43c3cd",
  },
};

export default Palette;
