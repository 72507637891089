import React, { ReactElement } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { StyledProps } from "@mui/styles/styled";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import notFoundImage from "../assets/images/not_found.png";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {RoleName, useAllowedRoutes, useSession} from "../lib/session";
import getUserRoles from "../lib/rbac";
import NavLink from "../components/NavLink";
import { menuRoutes, adminRoutes, viewerMenuRoutes } from "../routes";
import palette from "../theme/palette";

interface StyleProps {
  projectImage?: string;
}
const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: {
    backgroundColor: palette.navigation.background,
  },
  sidebar: {
    scrollbarWidth: "none",
    height: "100%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  project: {
    cursor: "pointer",
    paddingTop: theme.spacing(2),
    "& $listItemIcon": {
      color: palette.navigation.activeText,
      marginLeft: "-4px",
    },
    "& $listItemText": {
      color: palette.primary.contrastText,
    },
    "& + &": {
      marginTop: theme.spacing(1),
    },
    tooltip: {
      color: theme.palette.primary.main,
    },
  },
  projectAvatar: {
    width: "60px",
    height: "60px",
  },
  projectIcon: {
    paddingRight: theme.spacing(2),
  },
  MuiAccordionroot: {
    backgroundColor: palette.navigation.background,
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      display: "none",
    },
    "&.MuiAccordionDetails-root": {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px",
    },
    "&.MuiAccordionSummary-content.Mui-expanded": {
      margin: theme.spacing(0),
    },
  },
  bottomDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    backgroundColor: palette.navigation.active,
  },
  list: {
    height: "calc(100% - 150px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      height: "calc(100% - 150px - 64px)",
    },
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      color: palette.navigation.active,
      backgroundColor: palette.navigation.active,
      borderLeft: `4px solid ${theme.palette.grey[400]}`,
      borderRadius: "4px",
      "& $listItemIcon": {
        color: palette.navigation.activeText,
        marginLeft: "-4px",
      },
      "& $listItemText": {
        color: palette.navigation.activeText,
      },
    },
    "& + &": {
      marginTop: theme.spacing(1),
    },
    tooltip: {
      color: theme.palette.primary.main,
    },
  },
  activeListItem: {
    borderLeft: `4px solid ${palette.navigation.active}`,
    borderRadius: "4px",
    backgroundColor: palette.navigation.active,
    "& $listItemText": {
      color: palette.navigation.activeText,
    },
    "& $listItemIcon": {
      color: palette.navigation.activeText,
      marginLeft: "-4px",
    },
  },
  parentMenu: {
    color: palette.navigation.inactiveText,
    marginLeft: theme.spacing(2),
    fontSize: "0.875rem",
    fontWeight: 450,
    textTransform: "uppercase",
    fontFamily: "Poppins",
    height: "24px",
  },
  parentOverride: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  listItemIcon: {
    marginRight: 0,
    color: palette.navigation.inactiveText,
  },
  listItemText: {
    fontSize: "0.875rem",
    fontWeight: 450,
    textTransform: "uppercase",
    fontFamily: "Poppins",
    color: palette.navigation.inactiveText,
  },
  iconColor: {
    color: palette.navigation.inactiveText,
  }
}));

interface ItemProps {
  icon: ReactElement;
  label: string;
  component?: ReactElement;
}

interface MenuProps {
  name: string;
  icon: ReactElement;
  path: string;
}

interface NavItemProps extends ItemProps {
  path: string;
}
const NavItem = ({ path, icon, label }: NavItemProps) => {
  const classes = useStyles({});
  return (
    <ListItem
      activeClassName={classes.activeListItem}
      className={classes.listItem}
      component={NavLink}
      to={path}
      exact
    >
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText
        classes={{ primary: classes.listItemText }}
        primary={label}
      />
    </ListItem>
  );
};

type Props = {
  setOpen: (open: boolean) => void;
} & StyledProps;

export default function Sidebar ({ className, setOpen }: Props): JSX.Element {
  const { t } = useTranslation();
  const { activeProject } = useSession();
  const userRoles: RoleName[] = getUserRoles(activeProject);
  const isViewer = userRoles.includes(RoleName.VIEWER);

  const allowedNormalRoutes = useAllowedRoutes(
    activeProject.id,
    menuRoutes,
    userRoles
  );
  const allowedAdminRoutes = useAllowedRoutes(
    activeProject.id,
    adminRoutes,
    userRoles
  );
  const allowedViewerRoutes = useAllowedRoutes(
    activeProject.id,
    viewerMenuRoutes,
    userRoles
  )
  // TODO: Remove isViewer clause once we solidify future RBAC approach
  const routes = isViewer ? allowedViewerRoutes : allowedNormalRoutes.concat(allowedAdminRoutes);
  const groupedRoutes = Array.from(
    routes
      .reduce((r, o) => {
        const key = o.parentMenu;
        const item =
          r.get(key) ||
          Object.assign({}, o, {
            allowedRoutes: [],
            parentOverride: false,
            parentIcon: null,
          });
        item.allowedRoutes.push(o);
        item.parentOverride = o.parentOverride;
        o.parentIcon && (item.parentIcon = o.parentIcon);
        return r.set(key, item);
      }, new Map())
      .values()
  ).map((m) => {
    return {
      parentMenu: m.parentMenu,
      parentOverride: m.parentOverride,
      parentIcon: m.parentIcon,
      allowedRoutes: m.allowedRoutes,
    };
  });
  const classes = useStyles({ projectImage: activeProject.image });
  return (
    <div className={classes.sidebar}>
      <nav className={classNames(classes.root, className)}>
        <ListItem
          className={classes.project}
          component={NavLink}
          to={"/projects"}
          exact
          onClick={() => setOpen(false)}
        >
          <Tooltip title="Switch projects" placement="right">
            <ListItemIcon className={classes.projectIcon}>
              <Avatar className={classes.projectAvatar} src={activeProject.image ?? notFoundImage} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={activeProject.name}
          />
        </ListItem>
        <Divider className={classes.bottomDivider} />
        {groupedRoutes.map((g) =>
          g.parentOverride ? (
            <div className={classes.parentOverride} key={g.parentMenu}>
              {g.allowedRoutes.map(({ name, icon, path }: MenuProps) => (
                <NavItem key={name} path={path} label={t(name)} icon={icon} />
              ))}
            </div>
          ) : (
            <Accordion
              key={g.parentMenu}
              classes={{
                root: classes.MuiAccordionroot,
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.iconColor} />}>
                <div className={classes.parentMenu}>
                  <ListItemIcon className={classes.listItemIcon}>
                    {g.parentIcon}
                  </ListItemIcon>
                  {g.parentMenu}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  className={classes.list}
                  component="div"
                  disablePadding
                  key={g.parentMenu}
                >
                  {g.allowedRoutes.map(({ name, icon, path }: MenuProps) => (
                    <NavItem
                      key={name}
                      path={path}
                      label={t(name)}
                      icon={icon}
                    />
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )
        )}
      </nav>
    </div>
  );
};
