import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

interface Task {
  id: string;
  startDate: string | null;
  endDate: string | null;
}

export type ScheduleTask = Task | null;

export const updateScheduleTaskActionCreator = createAction<ScheduleTask>(
  "UPDATE_SCHEDULE_TASK"
);

export const scheduleTaskReducer = createReducer<ScheduleTask>(null, {
  [updateScheduleTaskActionCreator.type]: (
    _,
    action: PayloadAction<ScheduleTask>
  ) => action.payload,
});
