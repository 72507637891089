import React, { Component, PropsWithChildren } from "react";
import { isUnAuthorizedError } from "../lib/apollo";
import { Typography, Box, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import { ApolloError } from "@apollo/client";
import { Spinner } from "./Loader";

import Center from "./Center";

interface ErrorMessageProps {
  errorLabelId: string;
  className?: string;
}

export class NotFoundError extends Error {}
const getErrorLabelId = (error: Error): string => {
  if (error instanceof NotFoundError) {
    return "not found error";
  } else if (error instanceof ApolloError && isUnAuthorizedError(error)) {
    return "unauthorized";
  } else {
    return "unexpected error";
  }
};

const useStyle = makeStyles((theme: Theme) => ({
  errorBox: {
    color: theme.palette.text.primary,
    textTransform: "capitalize",
    fontWeight: 400
  }
}));

export const ErrorMessage = ({
  errorLabelId,
  className
}: ErrorMessageProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <Center className={className}>
      <Typography variant="h6">
        {errorLabelId === "unauthorized" && <Spinner />}
        <Box className={classes.errorBox}>{t(errorLabelId)}</Box>
      </Typography>
    </Center>
  );
};

type Props = PropsWithChildren<{}>;
type State = Partial<Pick<ErrorMessageProps, "errorLabelId">>;

export default class extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    const errorLabelId = getErrorLabelId(error);
    if (errorLabelId) {
      console.error(errorLabelId, error);
    }
    return { errorLabelId };
  }

  render() {
    const { errorLabelId } = this.state;
    return errorLabelId ? (
      <ErrorMessage errorLabelId={errorLabelId} />
    ) : (
      this.props.children
    );
  }
}
