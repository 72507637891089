import { createContext, useContext, useMemo } from "react";

import { useAllowedRoutes as uAR } from "./permission";
import { useCurrentUserQuery } from "../newGraphql/generated/User.generated";
import { webAssetUrl } from "./endpoints";


export interface ProjectInterface {
  id: string,
  name: string,
  finishDate: string;
  image: string
}
export type ProjectUndefined = ProjectInterface | { id: string, name: undefined, bims: undefined, towers: undefined, webAssets: undefined, image: string | undefined };

interface Session {
  user: {id: string|undefined,  externalId: string|undefined, username: string|undefined, name: string|undefined, user: string|undefined };
  roles: Array<{
    projectId: string|undefined;
    roleName: string|undefined;
  }>;
  projects: ProjectInterface[]
  activeProject: ProjectUndefined;
  /** UserActiveProject db entry (last active session persisted on db) */
  userActiveProject: { id: string|undefined, projectId: string|undefined };
  /**
   * Returns a boolean if current user has provided role for project
   * @param role
   * @param project
   */
  currentUserHasRole: (role: RoleName, project: ProjectUndefined) => boolean;
}

export enum RoleName {
  USER = "user",
  ADMIN = "admin",
  VIEWER = "viewer",
  SITEADMIN = "site_admin",
}

const defaultSession: Session = {
  user: {
    id: undefined,
    externalId: undefined,
    username: undefined,
    name: undefined,
    user: undefined,
  },
  roles: [],
  projects: [],
  activeProject: {
    id: '',
    name: undefined,
    bims: undefined,
    towers: undefined,
    webAssets: undefined,
    image: undefined
  },
  userActiveProject: {
    id: undefined,
    projectId: undefined,
  },
  currentUserHasRole: () => false,
};

const LAST_HREF_KEY = "session_last_href";

export const SessionContext = createContext<Session>(defaultSession);

export const SessionContextProvider = ({children, ...rest}: any) => {
  const { data, loading } = useCurrentUserQuery();

  const currentUserHasRole = (role: RoleName, project: ProjectInterface): boolean => {
    const roles = data?.currentUser.roles ?? [];
    const { id } = project;
    return !!roles.find( t => t.projectId === id && t.roleName === role);
  }

  // extract projects with image url, activeProject and userActiveProject from data
  const { projects, activeProject, userActiveProject } = useMemo(() => {
    const _projects = data?.currentUser.projects?? [];
    const projects = _projects.map(p => ({
      ...p,
      image: p.webAssetId? webAssetUrl(p.webAssetId) : undefined,
    }));
    const userActiveProject = {
      id: data?.currentUser.user.activeProject.id?? undefined,
      projectId: data?.currentUser.user.activeProject.projectId ?? data?.currentUser.roles?.[0].projectId
    };
    const activeProject = projects.find(({ id }) => id === userActiveProject.projectId)
      ?? projects?.[0]
      ?? defaultSession.activeProject;

    return { projects, activeProject, userActiveProject };
  }, [data]);
  if(loading) return null;
  return (
    <SessionContext.Provider
      value={{
        user: data?.currentUser.user ?? defaultSession.user,
        roles: data?.currentUser.roles ?? defaultSession.roles,
        projects,
        activeProject,
        userActiveProject,
        currentUserHasRole: (role: RoleName, project: ProjectInterface) => currentUserHasRole(role, project)
      }}
      {...rest}
    >
      {children}
    </SessionContext.Provider>
  );
}

/**
 * Returns a session context
 */
export const useSession = (): Session => {
  const context = useContext<Session>(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionContextProvider.");
  }
  return context;
};


/**
 * Redirect to last session from local storage
 */
export const restoreSession = (): boolean => {
  const url = localStorage.getItem(LAST_HREF_KEY);
  if (url) {
    localStorage.removeItem(LAST_HREF_KEY);
    window.location.href = url;
    return true;
  } else {
    return false;
  }
};

/**
 * @deprecated please use "useAllowedRoutes" from "permission.ts"
 */
export const useAllowedRoutes = uAR;

/**
 * @deprecated please destruct "userActiveProject" from "useSession" context
 *
 * Returns the active project for the user
 */
export const useUserActiveProject = () => {
  const { activeProject } = useSession();
  return {
    id: undefined,
    projectId: activeProject.id,
  }
};

export default useSession;
