import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";

export type ActiveBimTaskIds = Array<string>;

export const updateActiveBimTaskIdsActionCreator = createAction<
  ActiveBimTaskIds
>("UPDATE_ACTIVE_BIM_TASK_IDS");

export const activeBimTaskIdsReducer = createReducer<ActiveBimTaskIds>(
  Array(0),
  {
    [updateActiveBimTaskIdsActionCreator.type]: (
      _,
      action: PayloadAction<ActiveBimTaskIds>
    ) => action.payload,
  }
);
