import { parse, stringify } from "qs";
import { History, createHashHistory, Location } from "history";
import { matchPath } from "react-router";
import { Route } from "../routes";

const history = createHashHistory();

const useHistory = (): History => {
  return history;
};

const useMatch = <P extends { [K in keyof P]?: string | undefined; }>({ path }: Route) => {
  const {
    location: { pathname },
  } = useHistory();
  return matchPath<P>(pathname, {
    path,
    exact: true,
    sensitive: true,
    strict: true,
  });
};

export const useUrlParams = (): Record<string, string> => {
  const {
    location: { search },
  } = useHistory();
  const urlParams = parse(search, { ignoreQueryPrefix: true });
  return (urlParams as Record<string, string>) || {};
};

interface LocationWithQuery extends Location {
  query: Record<string, any>;
}
export const useSetLocation = () => {
  const { location, push } = useHistory();
  return (l: Partial<LocationWithQuery>) =>
    push({
      ...location,
      ...l,
      search: l.query ? stringify(l.query) : location.search,
    });
};

export const useReplaceLocation = () => {
  const { location, replace } = useHistory();
  return (l: Partial<LocationWithQuery>) =>
    replace({
      ...location,
      ...l,
      search: l.query ? stringify(l.query) : location.search,
    });
};

export const useIdFromPathname = (route: Route) => {
  const match = useMatch<{ id: string }>(route);
  return match && match.params.id;
};

export default useHistory;
