import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/style.css";
import { I18Provider } from "./lib/i18";
import theme from "./theme";
import { Router, Content } from "./routes";
import ApolloProvider from "./components/ApolloProvider";
import { SessionContextProvider } from "./lib/session";
import Loader from "./components/Loader";
import Layout from "./layouts";
import { useAuth0 } from "./components/react-auth0-spa";
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

export default () => {
  const {isAuthenticated, isTokenStored} = useAuth0();
  return (
    <I18Provider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ConfirmProvider>
              <Loader>
                <ApolloProvider>
                  {isAuthenticated && isTokenStored && (
                    <SessionContextProvider>
                      <Router>
                        <Layout>
                          <Content/>
                        </Layout>
                      </Router>
                    </SessionContextProvider>
                  )}
                </ApolloProvider>
              </Loader>
            </ConfirmProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </I18Provider>
  );
};
