import React, { PropsWithChildren } from "react";
import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";

const createPercent = (lang: string) =>
  new Intl.NumberFormat(lang, { style: "percent" });

const namespace = "common";
const defaultLang = "en";
let percent = createPercent(defaultLang);

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    debug: import.meta.env.VITE_NODE_ENV !== "production",
    partialBundledLanguages: true,
    ns: namespace,
    defaultNS: namespace,
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false,
      // @ts-ignore
      format: function(value: any, format: string) {
        switch (format) {
          case "percent":
            return percent.format(value);
          default:
            return value;
        }
      }
    }
  }, (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
  });

i18n.on("languageChanged", lang => {
  percent = createPercent(lang);
  switch (lang) {
    case "en":
      return import("./en").then(() => moment.locale(lang));
    default:
      return console.error(`Unsupported language ${lang}`);
  }
});

interface I18ProviderProps {
  ns?: string;
}
export const I18Provider = ({
  ns,
  ...rest
}: PropsWithChildren<I18ProviderProps>) => (
  <I18nextProvider {...rest} i18n={i18n} defaultNS={ns ? ns : namespace} />
);

// export const PickerUtilsProvider = (
//   props: Omit<{
//     utils: any;
//     children: React.ReactNode;
//     locale?: any;
//     libInstance?: any;
//   }, "utils" | "locale">
// ) => {
//   const { i18n } = useTranslation();
//   return (
//     <LocalizationProvider
//       dateAdapter={AdapterDateFns}
//       utils={MomentUtils}
//       locale={i18n.language}
//       {...props}
//     />
//   );
// };

export default i18n;
