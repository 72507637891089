import palette from "./palette";

const Typography = {
  fontFamily: "Poppins",
  h1: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "35px",
    letterSpacing: "-0.24px",
    lineHeight: "40px"
  },
  h2: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "24px",
    letterSpacing: "-0.24px",
    lineHeight: "32px"
  },
  h3: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "18px",
    letterSpacing: "-0.06px",
    lineHeight: "28px"
  },
  h4: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "0.1px",
    lineHeight: "24px"
  },
  h5: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 450,
    fontSize: "16px",
    letterSpacing: "0.1px",
    lineHeight: "20px"
  },
  h6: {
    fontFamily: "Poppins",
    color: palette.text.primary,
    fontWeight: 300,
    fontSize: "12px",
    letterSpacing: "0.1px",
    lineHeight: "20px"
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: "16px",
    letterSpacing: "0.1px",
    lineHeight: "25px"
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "16px"
  },
  body1: {
    color: palette.text.primary,
    fontSize: "14px",
    letterSpacing: "0.1px",
    lineHeight: "21px"
  },
  body2: {
    color: palette.text.primary,
    fontSize: "12px",
    letterSpacing: "-0.04px",
    lineHeight: "14px"
  },
  button: {
    color: palette.text.primary,
    fontSize: "14px"
  },
  caption: {
    color: palette.caption.text,
    letterSpacing: "0.3px",
    lineHeight: "16px",
    fontSize: "12px"
  }
};

export default Typography;
