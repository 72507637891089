const MuiSvgIcon = {
  styleOverrides: {
    root: {
      height: "22px",
      width: "22px",
      fontSize: "22px"
    }
  }
};

export default MuiSvgIcon;
