// Palette
import palette from "../palette";

const MuiTableCell = {
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
      padding: "14px 40px 14px 24px"
    },
    head: {
      fontSize: "14px",
      color: palette.text.primary
    }
  }
};

export default MuiTableCell;
