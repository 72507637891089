import React, { PropsWithChildren } from "react";
import { ApolloProvider } from "@apollo/client";

import useApolloClient from "../lib/apollo";
import { useAuth0 } from "../components/react-auth0-spa";

export default ({ children }: PropsWithChildren<{}>) => {
  const { loginWithRedirect } = useAuth0();
  const client = useApolloClient({ loginWithRedirect });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
